var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"form-wrapper full"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.warehouse.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.warehouse.id},on:{"organizationChanged":(organizationId, organization) => {
            _vm.warehouse.organization.id = organizationId;
            _vm.warehouse.allowedLocations = [];
            _vm.warehouse.taxes = organization.taxes;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1),_c('div',{staticClass:"form-wrapper full"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.warehouse.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.warehouse.organization.id},on:{"locationsChanged":(locations) => {
            _vm.warehouse.allowedLocations = locations;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"placeholder":_vm.$t('COMMON.NAME')},on:{"change":_vm.onFormChanged},model:{value:(_vm.warehouse.name),callback:function ($$v) {_vm.$set(_vm.warehouse, "name", $$v)},expression:"warehouse.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-checkbox',{staticClass:"mb-3",on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.warehouse.allow_negative_inventory),callback:function ($$v) {_vm.$set(_vm.warehouse, "allow_negative_inventory", $$v)},expression:"warehouse.allow_negative_inventory"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("WAREHOUSES.ALLOW_NEGATIVE_INVENTORY"))+" ")])]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.allow_negative_inventory}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-checkbox',{staticClass:"mb-3",on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.warehouse.allow_unregistered_products),callback:function ($$v) {_vm.$set(_vm.warehouse, "allow_unregistered_products", $$v)},expression:"warehouse.allow_unregistered_products"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("WAREHOUSES.ALLOW_UNREGISTERED_PRODUCTS"))+" ")])]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.allow_unregistered_products}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('taxes-selector',{attrs:{"label":_vm.$t('COMMON.DEFAULT_TAXES'),"taxes":_vm.warehouse.taxes},on:{"taxesChanged":(taxes) => {
          _vm.warehouse.taxes = taxes;
          _vm.onFormChanged();
        }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.taxes}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EXCERPT')}`,"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.warehouse.excerpt),callback:function ($$v) {_vm.$set(_vm.warehouse, "excerpt", $$v)},expression:"warehouse.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1)])]),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.warehouse.id ? _vm.$t("WAREHOUSES.EDIT_WAREHOUSE") : _vm.$t("WAREHOUSES.ADD_WAREHOUSE"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }