<template>
  <div class="container-fluid">
    <warehouse-form
      :loading="loading"
      :warehouseData="warehouse"
      :formErrors="formErrors"
      @warehouseSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultWarehouse from "../defaultWarehouse";
import WarehouseForm from "../partials/WarehouseForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    WarehouseForm,
  },

  mixins: [alertLeave],

  data() {
    const warehouse = cloneDeep(defaultWarehouse);
    return {
      warehouse: warehouse,
      formErrors: null,
      loading: false,
    };
  },

  methods: {
    async handleSubmit(warehouse) {
      this.loading = true;
      const warehouseData = cloneDeep(warehouse);

      try {
        await this.$store.dispatch("warehouses/add", warehouseData);
        this.$notify({
          type: "success",
          message: this.$t("WAREHOUSES.WAREHOUSE_ADDED"),
        });
        const warehouse = await this.$store.getters["warehouses/warehouse"];
        this.$emit("onViewWarehouse", warehouse, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
