<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ `${warehouse.name}` }}</h3>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ warehouse.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("WAREHOUSES.ALLOW_NEGATIVE_INVENTORY") }}
          </dt>
          <dd class="col-sm-8">
            <icon-check :checked="!!warehouse.allow_negative_inventory" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("WAREHOUSES.ALLOW_UNREGISTERED_PRODUCTS") }}
          </dt>
          <dd class="col-sm-8">
            <icon-check :checked="!!warehouse.allow_unregistered_products" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.DEFAULT_TAXES") }}</dt>
          <dd class="col-sm-8">
            <dl class="row" v-for="(tax, key) in warehouse.taxes" :key="key">
              <dt class="col-sm-4">{{ tax.name }}</dt>
              <dd class="col-sm-8" v-if="tax.type === TAX_TYPE_PERCENTAGE">
                {{ tax.value }} %
              </dd>
              <dd class="col-sm-8" v-if="tax.type === TAX_TYPE_AMOUNT">
                {{ $formatCurrency(tax.value) }}
              </dd>
            </dl>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            <div v-html="warehouse.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="warehouse.allowedLocations" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="warehouse.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(warehouse.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(warehouse.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT } from "@/constants/taxes";
import IconCheck from "@/components/IconCheck.vue";

export default {
  name: "warehouse-view-global",

  props: ["warehouse"],

  components: { IconCheck },

  data() {
    return { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT };
  },

  computed: {
    warehouseableName() {
      if (this.warehouse.warehouseable.type == "suppliers") {
        return this.warehouse.warehouseable.company_name;
      } else if (this.warehouse.warehouseable.type == "customers") {
        return this.warehouse.warehouseable.customer_name;
      }
      return "N/A";
    },
    warehouseableTypeName() {
      if (this.warehouse.warehouseable.type == "suppliers") {
        return this.$t("COMMON.SUPPLIER");
      } else if (this.warehouse.warehouseable.type == "customers") {
        return this.$t("COMMON.CUSTOMER");
      }
      return "N/A";
    },
  },

  created() {},

  methods: {
    warehouseUpdated() {
      this.$emit("warehouseUpdated", true);
    },
  },

  mounted() {},

  watch: {
    warehouse(warehouse) {},
  },
};
</script>
