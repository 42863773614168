<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper full">
      <!-- Organization -->
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <organization-selector
          :organization="warehouse.organization.id"
          :filterable="true"
          :showAll="false"
          @organizationChanged="
            (organizationId, organization) => {
              warehouse.organization.id = organizationId;
              warehouse.allowedLocations = [];
              warehouse.taxes = organization.taxes;
              onFormChanged();
            }
          "
          :disabled="!!warehouse.id"
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.LOCATION')}`"
        :placeholder="$t('COMMON.LOCATION')"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
      >
        <locations-selector
          :locations="warehouse.allowedLocations"
          :filterable="true"
          :showAll="false"
          :multiple="true"
          :organization="warehouse.organization.id"
          @locationsChanged="
            (locations) => {
              warehouse.allowedLocations = locations;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.location" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.NAME')} (*)`"
        :placeholder="$t('COMMON.NAME')"
        v-model="warehouse.name"
        @change="onFormChanged"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </div>

    <div class="form-wrapper full">
      <base-checkbox
        v-model="warehouse.allow_negative_inventory"
        class="mb-3"
        @change="
          () => {
            onFormChanged();
          }
        "
      >
        <span class="form-control-label">
          {{ $t("WAREHOUSES.ALLOW_NEGATIVE_INVENTORY") }}
        </span>
      </base-checkbox>
      <validation-error
        :errors="apiValidationErrors.allow_negative_inventory"
      />
    </div>

    <div class="form-wrapper full">
      <base-checkbox
        v-model="warehouse.allow_unregistered_products"
        class="mb-3"
        @change="
          () => {
            onFormChanged();
          }
        "
      >
        <span class="form-control-label">
          {{ $t("WAREHOUSES.ALLOW_UNREGISTERED_PRODUCTS") }}
        </span>
      </base-checkbox>
      <validation-error
        :errors="apiValidationErrors.allow_unregistered_products"
      />
    </div>

    <div class="form-wrapper full">
      <taxes-selector
        :label="$t('COMMON.DEFAULT_TAXES')"
        :taxes="warehouse.taxes"
        @taxesChanged="
          (taxes) => {
            warehouse.taxes = taxes;
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.taxes" />
    </div>

    <div class="form-wrapper full">
      <!-- Excerpt -->
      <div class="row">
        <div class="col">
          <base-input
            :label="`${$t('COMMON.EXCERPT')}`"
            :placeholder="$t('COMMON.EXCERPT')"
          >
            <html-editor v-model="warehouse.excerpt" @change="onFormChanged()">
            </html-editor>
          </base-input>
          <validation-error :errors="apiValidationErrors.excerpt" />
        </div>
      </div>
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          warehouse.id
            ? $t("WAREHOUSES.EDIT_WAREHOUSE")
            : $t("WAREHOUSES.ADD_WAREHOUSE")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import TaxesSelector from "@/components/TaxesSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    HtmlEditor,
    LocationsSelector,
    TaxesSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["warehouseData", "formErrors", "loading"],

  data() {
    let warehouseData = { ...this.warehouseData };
    warehouseData = this.$fillUserOrganizationData(warehouseData);
    return {
      warehouse: warehouseData,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let warehouseData = cloneDeep(this.warehouse);
      warehouseData = this.$fillUserOrganizationData(warehouseData);
      this.$emit("warehouseSubmitted", warehouseData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    warehouseData(warehouseData) {
      if (warehouseData) {
        this.warehouse = {
          ...this.warehouse,
          ...cloneDeep(warehouseData),
        };
      }
    },
  },
};
</script>
