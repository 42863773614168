export default {
  type: "warehouses",
  name: null,
  taxes: [],
  allow_negative_inventory: false,
  allow_unregistered_products: false,
  excerpt: "",
  relationshipNames: ["organization", "allowedLocations"],
  organization: {
    type: "organizations",
    id: null,
  },
  allowedLocations: [],
};
